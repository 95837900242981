import { isJwtTokenExpired } from '@allurion/utils';

import { Logger } from 'src/services/Logger';

import { fetchTwilioToken } from './TwilioApi';

const TOKEN_STORAGE_KEY = 'tw-conversations-token';

export class TwilioTokenService {
  private token?: string;
  private identity: string;

  constructor(identity: string) {
    this.log(`new identity ${identity}`);
    this.identity = identity;
    this.hydrate();
  }

  async getToken() {
    this.log('getting token');
    if (this.token && !this.isExpired()) {
      this.log('using cached token');

      return this.token;
    }

    this.clearToken();

    const token = await this.fetchToken();

    this.token = token;
    this.persistToken();

    return token;
  }

  async renewToken() {
    this.log('renewing token');
    this.clearToken();

    return this.getToken();
  }

  isExpired(): boolean {
    const token = this.token;

    if (!token) {
      return true;
    }

    return isJwtTokenExpired(token);
  }

  private async fetchToken() {
    this.log('fetching token');

    return fetchTwilioToken(this.identity);
  }

  private clearToken() {
    this.log('clearing token');

    this.token = undefined;
    localStorage.removeItem(TOKEN_STORAGE_KEY);
  }

  private persistToken() {
    if (!this.token) {
      return;
    }

    this.log('persisting token');
    const value = JSON.stringify({ token: this.token, identity: this.identity });

    localStorage.setItem(TOKEN_STORAGE_KEY, value);
  }

  private hydrate() {
    const value = localStorage.getItem(TOKEN_STORAGE_KEY);

    if (!value) {
      return;
    }

    this.log('hydrating token');

    try {
      const parsedValue = JSON.parse(value);

      const token = parsedValue.token;
      const identity = parsedValue.identity;

      if (identity !== this.identity) {
        this.log(`identity mismatch: ${identity} !== ${this.identity}`);
        this.clearToken();

        return;
      }

      this.token = token;

      if (this.isExpired()) {
        this.log('token expired');
        this.clearToken();
      }
    } catch (e) {
      this.log(`hydration failed: ${e}`);
      this.clearToken();
    }
  }

  private log(message: string) {
    Logger.captureMessage(`[twilio][token]: ${message}`);
  }
}
