import { defer } from '@allurion/utils';
import Client from '@twilio/conversations';

import { Logger } from 'src/services/Logger';

import { TwilioTokenService } from './TwilioToken';

export class TwilioClient {
  private tokenService: TwilioTokenService;
  private isConnecting = false;
  private client: Client | null = null;
  private pendingClients: {
    resolve?: () => void;
    reject?: (error: unknown) => void;
  }[] = [];

  constructor(identity: string) {
    this.tokenService = new TwilioTokenService(identity);
  }

  async getClient() {
    this.log('getting client');
    const haveValidClient = this.client && !this.tokenService.isExpired();

    if (haveValidClient) {
      this.log('using cached client');

      return this.client;
    }

    if (this.tokenService.isExpired()) {
      this.log('token expired');
      await this.disconnect();
      await this.tokenService.renewToken();
    }

    if (!this.client) {
      this.log('client not found');
      await this.connect();
    }

    return this.client;
  }

  private isConnected() {
    this.log(`checking connection: [${this.client?.connectionState}]`);

    return this.client?.connectionState === 'connected';
  }

  private async connect() {
    if (this.isConnecting) {
      this.log('already connecting');
      const d = defer();

      this.pendingClients.push(d);

      return d.promise;
    }

    this.isConnecting = true;

    const token = await this.tokenService.getToken();

    try {
      this.log('connecting');
      this.client = await Client.create(token);

      this.log(`client created`);

      this.isConnecting = false;
      this.log('resolving pending clients');
      for (const { resolve } of this.pendingClients) {
        resolve?.();
      }
    } catch (error) {
      this.log(`failed to connect: ${error}`);
      Logger.captureException(error);

      // when client connection fails, reject all pending clients
      this.log('rejecting pending clients');
      for (const { reject } of this.pendingClients) {
        reject?.(error);
      }
    }

    this.isConnecting = false;
    this.pendingClients = [];
  }

  async disconnect() {
    this.log('disconnecting');

    if (this.client) {
      this.client.removeAllListeners();
      this.client.shutdown();
    }

    this.client = null;
  }

  private log(message: string) {
    Logger.captureMessage(`[twilio][client]: ${message}`);
  }
}
