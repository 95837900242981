export function wait(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function waitForCondition(
  condition: () => Promise<boolean>,
  { interval = 1000, maxAttempts = 10 } = {}
): Promise<boolean> {
  return new Promise((resolve) => {
    setTimeout(() => {
      condition().then((result) => {
        if (result) {
          return resolve(true);
        }

        if (maxAttempts <= 0) {
          return resolve(false);
        }

        resolve(waitForCondition(condition, { interval, maxAttempts: maxAttempts - 1 }));
      });
    }, interval);
  });
}

export function defer() {
  const defer = {
    resolve: undefined as (() => void) | undefined,
    reject: undefined as ((error: unknown) => void) | undefined,
    promise: undefined as Promise<void> | undefined,
  };

  defer.promise = new Promise<void>((resolve, reject) => {
    defer.resolve = resolve;
    defer.reject = reject;
  });

  return defer;
}
