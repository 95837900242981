import Client from '@twilio/conversations';
import { Conversation } from '@twilio/conversations/lib/conversation';

import { Logger } from 'src/services/Logger';

import { fetchTwilioToken } from './TwilioApi';
import { TwilioClient } from './TwilioClient';

class TwilioConversationsService {
  client: Client | null = null;
  identity = '';
  subscriptions: Record<string, Function> = {};
  hasConnectionError = false;
  conversations: Conversation[] = [];
  private clientService?: TwilioClient;
  private connectionAttempts = 0;

  private async connect(identity: string) {
    if (this.connectionAttempts > 100) {
      throw new Error('Too many connection attempts');
    }

    this.connectionAttempts += 1;

    if (identity !== this.identity) {
      this.log('identity changed');
      this.clientService?.disconnect();
      this.identity = identity;
    }

    if (!this.clientService) {
      this.log('creating new client service');
      this.clientService = new TwilioClient(identity);
    }

    this.log('connect', identity);
    this.client = await this.clientService.getClient();

    if (!this.client) {
      throw new Error('Failed to connect to Twilio');
    }

    this.conversations = await this.getSubscribedConversations();

    return this.client;
  }

  async disconnect() {
    this.log('disconnecting');
    this.identity = '';

    const client = this.client;

    if (!client) {
      return;
    }

    this.client = null;

    client.removeAllListeners();

    await client.shutdown();
  }

  private async getSubscribedConversations() {
    const client = this.client!;

    const getNextPage = async (
      paginator: any,
      pageConversations: Conversation[]
    ): Promise<Conversation[]> => {
      if (paginator.hasNextPage) {
        const nextPageConversations = await paginator.nextPage();
        const combinedConversations = pageConversations.concat(nextPageConversations.items);

        return getNextPage(nextPageConversations, combinedConversations);
      }

      return pageConversations;
    };

    const result = await client.getSubscribedConversations();
    const pageConversations = result.items;

    return getNextPage(result, pageConversations);
  }

  async subscribe(identity: string, fn: Function) {
    await this.connect(identity);
    this.subscriptions[identity] = fn;
  }

  unsubscribe(identity: string) {
    delete this.subscriptions[identity];
  }

  private async getConversationByUniqueName(identity: string, patientIdentity: string) {
    this.log('getConversationByUniqueName', identity, patientIdentity);
    const client = await this.connect(identity);

    return client.getConversationByUniqueName(patientIdentity).catch(() => {
      return null;
    });
  }

  async waitForConversation(identity: string, patientIdentity: string): Promise<Conversation> {
    const client = await this.connect(identity);

    return new Promise((resolve, reject) => {
      let maxAttempts = 10;
      const interval = setInterval(async () => {
        const conversation = await client
          .getConversationByUniqueName(patientIdentity)
          .catch((err) => {
            Logger.captureException(err);
            reject(err);
          });

        if (conversation) {
          clearInterval(interval);
          resolve(conversation);
        }

        maxAttempts--;

        if (maxAttempts === 0) {
          clearInterval(interval);
          reject(new Error('Conversation not found'));
        }
      }, 1000);
    });
  }

  private async invitePatient({
    providerName,
    patientIdentity,
    identity,
  }: {
    identity: string;
    patientIdentity: string;
    providerName: string;
  }) {
    this.log('invitePatient', identity, patientIdentity, providerName);
    const inviteToken = await fetchTwilioToken(patientIdentity);

    const client = await Client.create(inviteToken);

    let conversation = await client.getConversationByUniqueName(patientIdentity).catch((e) => {
      Logger.captureException(e);
    });

    if (conversation) {
      this.log('Conversation found, adding participant', identity, patientIdentity, providerName);
      // In case of error lets consider that is no participants to avoid crashes
      const convParticipants = await conversation.getParticipants().catch((e) => {
        Logger.captureException(e);

        return [];
      });
      const filtered = convParticipants.filter((participant) => participant.identity === identity);

      if (!filtered?.length && identity) {
        await conversation.add(identity, { name: providerName });
      }
    } else {
      this.log('Conversation not found, creating new one', identity, patientIdentity, providerName);
      conversation = await client.createConversation({
        uniqueName: patientIdentity,
        friendlyName: patientIdentity,
      });
      await conversation.add(identity, { name: providerName });
      await conversation.add(patientIdentity);
    }

    await client.shutdown();

    return conversation;
  }

  private async updatePatientAttributes({
    patientIdentity,
    attributes,
  }: {
    patientIdentity: string;
    attributes: Record<string, string>;
  }) {
    const token = await fetchTwilioToken(patientIdentity);

    const client = await Client.create(token);

    const conv = await client.getConversationByUniqueName(patientIdentity);

    await conv.updateAttributes(attributes);

    await client.shutdown();
  }

  async setupConversation({
    identity,
    patientIdentity,
    patientId,
    providerName,
  }: {
    identity: string;
    patientIdentity: string;
    patientId: string;
    providerName: string;
  }) {
    this.log(
      '[setupConversation] Checking conversation',
      identity,
      patientIdentity,
      patientId,
      providerName
    );
    let conversation = await this.getConversationByUniqueName(identity, patientIdentity);

    if (!conversation) {
      this.log(
        '[setupConversation] Conversation not found, inviting patient',
        identity,
        patientIdentity,
        providerName
      );
      conversation = await this.invitePatient({ identity, patientIdentity, providerName });
    }
    {
      this.log('[setupConversation] Conversation found', identity, patientIdentity);
    }

    await this.updateConversationAttributes(conversation, patientIdentity, patientId);
  }

  private async updateConversationAttributes(
    conversation: Conversation,
    patientIdentity: string,
    patientId: string
  ) {
    const attributes = await conversation.getAttributes();

    if (!attributes?.patientId) {
      await this.updatePatientAttributes({
        patientIdentity,
        attributes: {
          patientId,
        },
      });
    }
  }

  log(...messages: string[]) {
    const logMessage = `[twilio][service]: ${messages.join(' ')}`;

    Logger.captureMessage(logMessage);
  }
}

export default new TwilioConversationsService();
